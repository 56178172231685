var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c(
        "div",
        { staticClass: "q-pa-md justify-center" },
        _vm._l(_vm.domande, function (Domanda, index) {
          return _c(
            "div",
            _vm._b(
              {
                key: index,
                staticClass: "row",
                on: {
                  input: function ($event) {
                    return _vm.resultDomande()
                  },
                },
              },
              "div",
              Domanda,
              false
            ),
            [
              _c("div", { staticClass: "col-md-3" }),
              _c(
                "div",
                { staticClass: "col-12 col-md-6" },
                [
                  _c("QQDomanda", {
                    attrs: {
                      questionario_completo: _vm.domande,
                      domanda: Domanda,
                      analisi_necessita: _vm.analisi_necessita,
                      visible: _vm.isDomandaVisible(Domanda),
                    },
                    on: {
                      "error-checked": function ($event) {
                        return _vm.onErrorChecked(index, $event)
                      },
                      "on-btn-clicked": _vm.onButtonClicked,
                      "qq-domanda-change": function ($event) {
                        return _vm.$emit("qq-questionario-change", Domanda)
                      },
                      "qq-domanda-filter": function ($event) {
                        return _vm.$emit("qq-questionario-filter", {
                          val: $event,
                          domanda: Domanda,
                        })
                      },
                      "qq-domanda-inputSelect-filter": function ($event) {
                        return _vm.$emit(
                          "qq-questionario-inputSelect-filter",
                          Object.assign({}, $event, { domanda: Domanda })
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-md-3" }),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }